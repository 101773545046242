import React from "react"
import Helmet from "react-helmet"
import {withPrefix} from "gatsby"


export default function RandomTaylorSwiftSong({ children }) {

  return (
      <div>
        <Helmet>
            <script src={withPrefix('scripts/randomTaylorSwiftSongScript.js')} type="text/javascript" />
        </Helmet>
            <p className="text" align="center" id="welcome">
                Welcome! If a song isn't playing, try pressing anything!
            </p>
            <p className="text" align="center">
                Press anything to get a new song!
            </p>
            <p className="text" id="nowplaying" align="center">
                Loading...
            </p>
    </div>
  )
}
