import React from "react"
import RandomTaylorSwiftSong from "../components/RandomTaylorSwiftSong"

export default function Home() {
  return (
    <div>
      <RandomTaylorSwiftSong></RandomTaylorSwiftSong>  
      <div id="player" className="invisible"></div>
    </div>
  )
}
